import { CartProduct } from "../types/cart.types";
import { CartItem } from "./cart-item";

interface CartItemListProps {
  items: Record<string, CartProduct>;
  onIncrease: (sku: string) => void;
  onDecrease: (sku: string) => void;
  onRemove: (sku: string) => void;
  getPrice: (sku: string, category: string, price: number) => number;
}

export const CartItemList = ({
  items,
  onIncrease,
  onDecrease,
  onRemove,
  getPrice,
}: CartItemListProps) => (
  <div className="mt-8">
    <div className="flow-root">
      <ul role="list" className="-my-6 divide-y divide-gray-200">
        {Object.values(items)
          .sort((a, b) => 
            a.eCommerceStoreName > b.eCommerceStoreName ? 1 : -1
          )
          .map((product) => (
            <CartItem
              key={product.SKU}
              product={product}
              onIncrease={onIncrease}
              onDecrease={onDecrease}
              onRemove={onRemove}
              getPrice={getPrice}
            />
          ))}
      </ul>
    </div>
  </div>
); 