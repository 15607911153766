import Header from "../header/header";
import Footer from "../footer/footer";
import Cart from "../../features/cart/cart";
import Alert, { AlertType } from "../../core/alert/alert";
import { useAlert } from "../../../lib/hooks/alert";

interface GeneralLayoutProps {
  children: React.ReactNode;
}

const GeneralLayout = ({ children }: GeneralLayoutProps) => {
  const { alert } = useAlert();

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <Cart />
      <Alert type={alert?.type} alert={alert as AlertType} />
    </>
  );
};

export default GeneralLayout;
