import { useRouter } from "next/router";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";

// Hooks
import { useCart } from "../../../lib/hooks/cart";
import { useAuth } from "../../../lib/hooks/auth";
import { usePrice } from "../../../lib/hooks/price";

// Components
import { CartHeader } from "./components/cart-header";
import { CartItemList } from "./components/cart-item-list";
import { CartFooter } from "./components/cart-footer";

export const Cart = () => {
  const { cartOpen, setCartOpen, cart, removeItem, increase, decrease } = useCart();
  const { getPrice, getSubtotal } = usePrice();
  const { userData } = useAuth();
  const router = useRouter();

  const handleCheckout = () => {
    if (Object.keys(cart).length) {
      router.push("/checkout");
    }
  };

  const handleContact = () => {
    router.push("/contact");
    setCartOpen(false);
  };

  return (
    <Dialog open={cartOpen} onClose={setCartOpen} className="relative z-20">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                  <CartHeader onClose={() => setCartOpen(false)} />
                  
                  {Object.keys(cart).length > 0 ? (
                    <CartItemList
                      items={cart}
                      onIncrease={increase}
                      onDecrease={decrease}
                      onRemove={removeItem}
                      getPrice={getPrice}
                    />
                  ) : (
                    <div className="mt-8 text-center text-gray-500">
                      No items in cart
                    </div>
                  )}
                </div>

                <CartFooter
                  subtotal={getSubtotal()}
                  isVerified={!userData?.contact.IsLeadStatusOnly}
                  onCheckout={handleCheckout}
                  onClose={() => setCartOpen(false)}
                  onContact={handleContact}
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Cart;
