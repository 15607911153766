import { FooterData } from "../types/footer.types";
import { FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, YouTubeIcon } from "../components/social-icons";

export const footerData: FooterData = {
  company: {
    title: "Company",
    links: [
      { name: "About us", href: "/company" },
      { name: "Find a dealer", href: "https://www.wavedistribution.com/dealers/" },
    ],
  },
  legal: {
    title: "Legal",
    links: [
      { name: "Shipping Information", href: "/legal/shippinginformation" },
      { name: "Returns Policy", href: "/legal/returnspolicy" },
      { name: "Privacy Policy", href: "/legal/privacypolicy" },
      { name: "Terms of Service", href: "/legal/terms" },
    ],
  },
  support: {
    title: "Support",
    links: [
      { name: "Contact us", href: "/contact" },
    ],
  },
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/wavedistro",
      icon: FacebookIcon,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/wavedistro",
      icon: InstagramIcon,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/wave-distribution",
      icon: LinkedInIcon,
    },
    {
      name: "Twitter",
      href: "https://x.com/wave_distro",
      icon: TwitterIcon,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCX5G5HQ5LM",
      icon: YouTubeIcon,
    },
  ],
};