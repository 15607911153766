import { FireIcon } from "@heroicons/react/outline";
import { getFileExt } from "../../../../utils/products/utils_products";
import { CartProduct } from "../types/cart.types";

interface CartItemImageProps {
  product: CartProduct;
}

export const CartItemImage = ({ product }: CartItemImageProps) => (
  <div className="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden p-2">
    {product.WDCatalogImage ? (
      <img
        src={`data:image/${getFileExt(product.WDCatalogImageFileName)};base64, ${product.WDCatalogImage}`}
        alt={product.eCommerceStoreName}
        className="w-full h-full object-center object-cover"
      />
    ) : (
      <FireIcon className="w-full h-full object-center object-cover p-4" />
    )}
  </div>
); 