import { DialogTitle } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CartHeaderProps } from "../types/cart.types";

export const CartHeader = ({ onClose }: CartHeaderProps) => (
  <div className="flex items-start justify-between">
    <DialogTitle className="text-lg font-medium text-gray-900">
      Shopping cart
    </DialogTitle>
    <div className="ml-3 h-7 flex items-center">
      <button
        type="button"
        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
        onClick={onClose}
      >
        <span className="sr-only">Close panel</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  </div>
);
