import { Transition, Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { Fragment } from "react";
import Link from "next/link";
import { useAuth } from "../../../../lib/hooks/auth";

interface Contact {
  FirstName: string;
  LastName: string;
  FullName: string;
  IsLeadStatusOnly?: boolean;
}

interface UserData {
  contact: Contact;
}

interface AuthContextType {
  signout: () => Promise<void>;
  userData: UserData | null;
}

const UserMenu = () => {
  const { signout, userData } = useAuth() as AuthContextType;

  return (
    <div className="relative inline-block text-left">
      <Menu as="div">
        {({ open }) => (
          <Fragment>
            <div>
              <MenuButton
                type="button"
                id="user-menu"
                aria-expanded={open}
                aria-haspopup="true"
                className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-primary focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-primaryDark focus:ring-primaryDark"
              >
                <span className="sr-only">Open user menu</span>
                <span className="font-medium leading-none text-white">
                  {userData?.contact.FirstName.charAt(0).toUpperCase()}
                  {userData?.contact.LastName.charAt(0).toUpperCase()}
                </span>
              </MenuButton>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <MenuItems
                className="z-20 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
              >
                <div className="px-4 py-3" role="none">
                  <p className="text-sm" role="none">
                    Signed in as
                  </p>
                  <p
                    className="text-sm font-medium text-gray-900 truncate"
                    role="none"
                  >
                    {userData?.contact.FullName}
                  </p>
                </div>
                <div className="py-1" role="none">
                  <MenuItem>
                    {({ active }) => (
                      <Link href="/dashboard">
                        <span
                          className={`group flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 ${
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          }`}
                          role="menuitem"
                        >
                          <svg
                            className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                              active ? "text-gray-500" : "text-gray-400"
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                          </svg>
                          Dashboard
                        </span>
                      </Link>
                    )}
                  </MenuItem>
                </div>
                <div className="py-1" role="none">
                  <MenuItem>
                    {({ focus }) => (
                      <div
                        onClick={() => signout()}
                        className={`group flex items-center px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer ${
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        }`}
                        role="menuitem"
                      >
                        <svg
                          className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                            focus ? "text-gray-500" : "text-gray-400"
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                        Sign out
                      </div>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Fragment>
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
