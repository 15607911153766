import Link from "next/link";
import { BrandItem } from "../../types/menu.types";

interface BrandListProps {
  brands: BrandItem[];
  onClose: () => void;
}

export const BrandList = ({ brands, onClose }: BrandListProps) => (
  <ul role="list" aria-labelledby="brand-heading" className="space-y-6 sm:space-y-4">
    {brands.map((brand, i) => (
      <Link href={`/brands/${brand.url}`} key={i}>
        <li
          onClick={onClose}
          className="flex items-center hover:cursor-pointer hover:bg-gray-100 rounded-tr-lg rounded-br-lg"
        >
          <div className="flex-shrink-0 rounded-lg inline-flex bg-gray-100 p-2 ring-4 ring-white">
            <img className="h-10 w-10" src={brand.logo} alt="" />
          </div>
          <div className="flex-1 min-w-0 ml-4">
            <p className="text-sm font-medium text-gray-900">{brand.displayName}</p>
            {brand.short_desc && (
              <p className="text-sm text-gray-500 truncate">{brand.short_desc}</p>
            )}
          </div>
        </li>
      </Link>
    ))}
  </ul>
); 