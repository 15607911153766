import Link from "next/link";
import { SocialLink } from "../types/footer.types";

interface SocialLinksProps {
  links: SocialLink[];
}

export const SocialLinks = ({ links }: SocialLinksProps) => (
  <div className="flex space-x-6 md:order-2">
    {links.map((item) => (
      <Link key={item.name} href={item.href} target="_blank">
        <span className="text-gray-400 hover:text-gray-300">
          <span className="sr-only">{item.name}</span>
          <item.icon className="h-6 w-6" aria-hidden="true" />
        </span>
      </Link>
    ))}
  </div>
); 