import { ReactElement } from 'react';

interface AlertStyle {
  icon: ReactElement;
  bg: string;
  title: string;
  message: string;
  button: string;
  hover: string;
  focus: string;
}

type AlertStylesType = {
  [key in 'success' | 'warning' | 'error' | 'info']: AlertStyle;
};

export const alertStyles: AlertStylesType = {
  success: {
    icon: (
      <svg
        className="h-5 w-5 text-green-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-green-50",
    title: "text-green-800",
    message: "text-green-700",
    button: "text-green-500",
    hover: "bg-green-100",
    focus: "bg-green-100",
  },
  warning: {
    icon: (
      <svg
        className="h-5 w-5 text-yellow-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-yellow-50",
    title: "text-yellow-800",
    message: "text-yellow-700",
    button: "text-yellow-500",
    hover: "bg-yellow-100",
    focus: "bg-yellow-100",
  },
  error: {
    icon: (
      <svg
        className="h-5 w-5 text-red-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-red-50",
    title: "text-red-800",
    message: "text-red-700",
    button: "text-red-500",
    hover: "bg-red-100",
    focus: "bg-red-100",
  },
  info: {
    icon: (
      <svg
        className="h-5 w-5 text-blue-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bg: "bg-blue-50",
    title: "text-blue-800",
    message: "text-blue-700",
    button: "text-blue-500",
    hover: "bg-blue-100",
    focus: "bg-blue-100",
  },
} as const; 