import { Fragment } from "react";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, Transition } from "@headlessui/react";
import { NavigationData } from "../types/header.types";
import { brand_data as brandData } from "../../../../utils/data/brands";
import { equipment_data as equipData } from "../../../../utils/data/equipment";
import { classNames } from "../../../../utils/helpers/class-names";
import { FeaturedMenuItem } from "./menu/featured-item";
import { BrandList } from "./menu/brand-list";
import { EquipmentList } from "./menu/equipment-list";

interface DesktopMenuProps {
  navigation: NavigationData;
}

export const DesktopMenu = ({ navigation }: DesktopMenuProps) => (
  <PopoverGroup className="hidden lg:ml-8 lg:block lg:self-stretch">
    <div className="h-full flex space-x-8">
      {navigation.categories.map((category) => (
        <Popover key={category.name} className="flex">
          {({ open }) => (
            <>
              <div className="relative flex">
                <PopoverButton
                  className={classNames(
                    open
                      ? "border-indigo-600 text-indigo-600"
                      : "border-transparent text-gray-700 hover:text-gray-800",
                    "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px"
                  )}
                >
                  {category.name}
                </PopoverButton>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <PopoverPanel className="absolute top-full inset-x-0 text-sm text-gray-500 z-40">
                  {({ close }) => (
                    <>
                      <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />
                      {category.id === "brands" && (
                        <div className="relative bg-white">
                          <div className="max-w-7xl mx-auto px-8">
                            <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                              <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                {category.featured.map((item) => (
                                  <FeaturedMenuItem key={item.name} item={item} />
                                ))}
                              </div>
                              <div className="row-start-1 grid grid-cols-2 gap-y-10 gap-x-8 text-sm truncate">
                                <div className="relative rounded-lg bg-white px-6 shadow-sm flex items-center space-x-3 max-w-full">
                                  <BrandList brands={brandData} onClose={close} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {category.id === "equipment" && (
                        <div className="relative bg-white">
                          <div className="max-w-7xl mx-auto px-8">
                            <div className="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                              <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                {category.featured.map((item) => (
                                  <FeaturedMenuItem key={item.name} item={item} />
                                ))}
                              </div>
                              <div className="row-start-1 grid grid-cols-2 text-sm truncate">
                                <div className="relative rounded-lg bg-white px-6 flex items-center space-x-3 max-w-full">
                                  <EquipmentList items={equipData.slice(0, 5)} onClose={close} />
                                </div>
                                <div className="relative rounded-lg bg-white px-6 flex items-center space-x-3 max-w-full">
                                  <EquipmentList items={equipData.slice(5, 10)} onClose={close} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </PopoverPanel>
              </Transition>
            </>
          )}
        </Popover>
      ))}

      {navigation.pages.map((page) => (
        <a
          key={page.name}
          href={page.href}
          className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
        >
          {page.name}
        </a>
      ))}
    </div>
  </PopoverGroup>
); 