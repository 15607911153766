import Link from "next/link";
import { Dialog, Tab, Transition, DialogBackdrop, TransitionChild, TabPanels, TabPanel, TabList, TabGroup } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { NavigationData } from "../types/header.types";
import { brand_data as brandData } from "../../../../utils/data/brands";
import { equipment_data as equipData } from "../../../../utils/data/equipment";
import { classNames } from "../../../../utils/helpers/class-names";

interface MobileMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  navigation: NavigationData;
}

export const MobileMenu = ({ open, setOpen, navigation }: MobileMenuProps) => (
  <Transition show={open}>
    <Dialog 
      as="div" 
      className="fixed inset-0 flex z-40 lg:hidden" 
      onClose={setOpen}
      open={open}
    >
      <TransitionChild
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogBackdrop className="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <TransitionChild
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
          <div className="px-4 pt-5 pb-2 flex">
            <button
              type="button"
              className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
              onClick={() => setOpen(false)}
            >
              <div className="sr-only">Close menu</div>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Links */}
          <TabGroup as="div" className="mt-2">
            <div className="border-b border-gray-200">
              <TabList className="-mb-px flex px-4 space-x-8">
                {navigation.categories.map((category) => (
                  <Tab
                    key={category.name}
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "text-indigo-600 border-indigo-600"
                          : "text-gray-900 border-transparent",
                        "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                      )
                    }
                  >
                    {category.name}
                  </Tab>
                ))}
              </TabList>
            </div>
            <TabPanels>
              {navigation.categories.map((category) => (
                <TabPanel
                  key={category.name}
                  className="pt-10 pb-8 px-4 space-y-10"
                >
                  {category.id === "brands" && (
                    <>
                      <div className="grid grid-cols-2 gap-x-4">
                        {category.featured.map((item) => (
                          <div
                            key={item.name}
                            className="group relative text-sm"
                          >
                            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                              <img
                                src={item.imageSrc}
                                alt={item.imageAlt}
                                className="object-center object-cover"
                              />
                            </div>
                            <Link href={item.href}>
                              <span className="mt-6 block font-medium text-gray-900">
                                <span
                                  className="absolute z-10 inset-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </span>
                            </Link>
                            <p aria-hidden="true" className="mt-1">
                              {item.text}
                            </p>
                          </div>
                        ))}
                      </div>
                      {brandData.map((brand, i) => (
                        <Link href={"/brands/" + brand.url} key={i}>
                          <li className="flex  items-center hover:cursor-pointer hover:bg-gray-100 rounded-tr-lg rounded-br-lg">
                            <div className="flex-shrink-0 rounded-lg inline-flex bg-gray-100 p-2 ring-4 ring-white">
                              <img
                                className="h-6 w-6"
                                src={brand.logo}
                                alt=""
                              />
                            </div>
                            <div className="flex-1 min-w-0 ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {brand.displayName}
                              </p>
                            </div>
                          </li>
                        </Link>
                      ))}
                    </>
                  )}
                  {category.id === "equipment" && (
                    <>
                      <div className="grid grid-cols-2 gap-x-4">
                        {category.featured.map((item) => (
                          <div
                            key={item.name}
                            className="group relative text-sm"
                          >
                            <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                              <img
                                src={item.imageSrc}
                                alt={item.imageAlt}
                                className="object-center object-cover"
                              />
                            </div>
                            <Link href={item.href}>
                              <span className="mt-6 block font-medium text-gray-900">
                                <span
                                  className="absolute z-10 inset-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </span>
                            </Link>
                            <p aria-hidden="true" className="mt-1">
                              {item.text}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="relative rounded-lg bg-white flex items-center space-x-3 max-w-full">
                        <ul
                          role="list"
                          aria-labelledby={`brand-heading`}
                          className="space-y-6 sm:space-y-4 w-full"
                        >
                          {equipData.map((item, i) => (
                            <li className="flow-root" key={i}>
                              <Link href={item.href}>
                                <span className="min-w-full -m-3 p-3 flex items-center space-x-4 rounded-md text-base leading-6 font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150">
                                  {item.icon && (
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" />
                                  )}
                                  {item.svg && item.svg}
                                  <span>{item.name}</span>
                                </span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>

          <div className="border-t border-gray-200 py-6 px-4 space-y-6">
            {navigation.pages.map((page) => (
              <div key={page.name} className="flow-root">
                <a
                  href={page.href}
                  className="-m-2 p-2 block font-medium text-gray-900"
                >
                  {page.name}
                </a>
              </div>
            ))}
          </div>

          <div className="border-t border-gray-200 py-6 px-4 space-y-6">
            <div className="flow-root">
              <Link href="/signin">
                <span className="-m-2 p-2 block font-medium text-gray-900">
                  Sign in
                </span>
              </Link>
            </div>
            <div className="flow-root">
              <Link href="/signup">
                <span className="-m-2 p-2 block font-medium text-gray-900">
                  Create account
                </span>
              </Link>
            </div>
          </div>
        </div>
      </TransitionChild>
    </Dialog>
  </Transition>
); 