import { NavigationData } from "../types/header.types";

export const navigation: NavigationData = {
  categories: [
    {
      id: "brands",
      name: "Brands",
      featured: [
        {
          name: "Kicktone Microphone",
          href: "/kicktone",
          imageSrc: "/features/kicktone.png",
          imageAlt: "Kicktone Microphone",
        },
        {
          name: "Whitestone - P331 EVL",
          href: "/WAI-P331-EVL",
          imageSrc: "/features/whitestone_evl.jpeg",
          imageAlt: "Whitestone - P331 EVL",
        },
      ],
    },
    {
      id: "equipment",
      name: "Equipment",
      featured: [
        {
          name: "ELI BIG-FrEQ",
          href: "/big-freq",
          imageSrc: "/features/big-freq.jpg",
          imageAlt: "ELI BIG-FrEQ.",
        },
        {
          name: "Strauss Nearfield Monitor",
          href: "/SE-NF-3",
          imageSrc: "/features/strauss.jpeg",
          imageAlt: "Strauss NF-3",
        },
      ],
    },
  ],
  pages: [
    { name: "Company", href: "/company" },
    { name: "Contact", href: "/contact" },
  ],
}; 