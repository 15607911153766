import Link from "next/link";
import { FeaturedItem } from "../../types/menu.types";

export const FeaturedMenuItem = ({ item }: { item: FeaturedItem }) => (
  <div className="group relative text-base sm:text-sm">
    <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
      <img
        src={item.imageSrc}
        alt={item.imageAlt}
        className="object-center object-cover"
      />
    </div>
    <Link href={item.href}>
      <div className="mt-6 block font-medium text-gray-900">
        <span className="absolute z-10 inset-0" aria-hidden="true" />
        {item.name}
      </div>
    </Link>
    <p aria-hidden="true" className="mt-1">Shop now</p>
  </div>
); 