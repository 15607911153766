import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useAlert } from "../../../lib/hooks/alert";
import { alertStyles } from "./styles/alert-styles";

export interface AlertType {
  type?: 'success' | 'warning' | 'error' | 'info';
  title?: string;
  message?: string;
}

interface AlertProps {
  type?: AlertType['type'];
  alert: AlertType | null;
}

const Alert = ({ type, alert }: AlertProps) => {
  const [active, setActive] = useState(false);
  const { setAlert } = useAlert();

  useEffect(() => {
    if (alert) {
      setActive(true);
    }
    const timer = setTimeout(() => {
      setActive(false);
      setAlert(null);
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
  }, [alert, setAlert]);

  if (!type || !alert) return null;

  const styles = alertStyles[type];

  return (
    <div className="fixed w-screen flex justify-center inset-x-0 bottom-0">
      <Transition
        show={active}
        appear
        unmount
        enter="transition linear duration-1000"
        enterFrom="transform translate-y-40"
        enterTo="transform translate-y-0"
        leave="transition linear duration-1000"
        leaveFrom="transform translate-y-0"
        leaveTo="transform translate-y-40"
      >
        <div className={`rounded-md ${styles.bg} p-4 z-10 w-11/12 md:w-full mx-auto mb-8 sm:mb-12`}>
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5">{styles.icon}</div>
            <div className="ml-3">
              <h3 className={`text-base leading-5 font-medium ${styles.title}`}>
                {alert.title}
              </h3>
              <div className={`mt-2 text-base leading-5 ${styles.message}`}>
                <p>{alert.message}</p>
              </div>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setActive(false)}
                  className={`inline-flex rounded-md p-1.5 ${styles.button} hover:${styles.hover} focus:outline-none focus:${styles.focus} transition ease-in-out duration-150`}
                  aria-label="Dismiss"
                >
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Alert; 