import { CartItemProps } from "../types/cart.types";
import { CartItemImage } from "./cart-item-image";
import { CartItemDetails } from "./cart-item-details";

export const CartItem = ({ 
  product, 
  onIncrease, 
  onDecrease, 
  onRemove, 
  getPrice 
}: CartItemProps) => (
  <li key={product.SKU} className="py-6 flex">
    <CartItemImage product={product} />
    <CartItemDetails
      product={product}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      onRemove={onRemove}
      getPrice={getPrice}
    />
  </li>
); 