import { DateTime } from "luxon";
import { FooterLinks } from "./components/footer-section";
import { SocialLinks } from "./components/social-links";
import { Newsletter } from "./components/newsletter";
import { footerData } from "./data/footer-data";

export const Footer = () => {
  return (
    <footer className="bg-gray-800">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <FooterLinks section={footerData.company} />
              <div className="mt-12 md:mt-0">
                <FooterLinks section={footerData.legal} />
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="mt-12 md:mt-0">
                <FooterLinks section={footerData.support} />
              </div>
            </div>
          </div>
          <div className="mt-12 xl:mt-0">
            <img
              className="h-10 mb-5"
              src="/logo/wavedistro-logo-white.svg"
              alt="Wave Distro"
            />
            <p className="text-gray-500 text-base leading-6">
              Pro audio distribution made easy.
            </p>
          </div>
        </div>
        
        <Newsletter />

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <SocialLinks links={footerData.social} />
          <p className="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
            &copy; {DateTime.now().toFormat("y")} Wave Distro. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 