import { CartCheckoutButton } from "./cart-checkout-button";
import { CartFooterProps } from "../types/cart.types";

export const CartFooter = ({ 
  subtotal, 
  isVerified, 
  onCheckout, 
  onClose, 
  onContact 
}: CartFooterProps) => (
  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
    <div className="flex justify-between text-base font-medium text-gray-900">
      <p>Subtotal</p>
      <p>${subtotal}</p>
    </div>
    <p className="mt-0.5 text-sm text-gray-500">
      Shipping and discounts calculated at checkout.
    </p>
    <div className="mt-6">
      <CartCheckoutButton
        isVerified={isVerified}
        onCheckout={onCheckout}
        onContact={onContact}
      />
    </div>
    <div className="mt-6 flex justify-center text-sm text-center text-gray-500">
      <p>
        or{" "}
        <button
          type="button"
          className="text-indigo-600 font-medium hover:text-indigo-500"
          onClick={onClose}
        >
          Continue Shopping<span aria-hidden="true"> &rarr;</span>
        </button>
      </p>
    </div>
  </div>
); 