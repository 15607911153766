import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/solid";
import { CartProduct } from "../types/cart.types";

interface CartItemDetailsProps {
  product: CartProduct;
  onIncrease: (sku: string) => void;
  onDecrease: (sku: string) => void;
  onRemove: (sku: string) => void;
  getPrice: (sku: string, category: string, price: number) => number;
}

export const CartItemDetails = ({ 
  product, 
  onIncrease, 
  onDecrease, 
  onRemove, 
  getPrice 
}: CartItemDetailsProps) => (
  <div className="ml-4 flex-1 flex flex-col">
    <div>
      <div className="flex justify-between text-base font-medium text-gray-900">
        <h3>
          <a href={product.href}>{product.eCommerceStoreName}</a>
        </h3>
        <p className="ml-4">
          ${getPrice(product.SKU, product.eCommerceCategory, product.SalesPrice)}
        </p>
      </div>
      <p className="mt-1 text-sm text-gray-500">
        {product.SKU}
      </p>
    </div>
    <div className="flex-1 flex items-end justify-between text-sm">
      <div className="flex items-center">
        <p className="text-gray-500">
          Quantity <strong>{product.quantity}</strong>
        </p>
        <span className="relative inline-flex shadow-sm rounded-md ml-2">
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              if (product.quantity > 1) {
                onDecrease(product.SKU);
              }
            }}
            className="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <span className="sr-only">Minus</span>
            <MinusSmIcon className="h-3 w-3" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              onIncrease(product.SKU);
            }}
            className="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <span className="sr-only">Plus</span>
            <PlusSmIcon className="h-3 w-3" aria-hidden="true" />
          </button>
        </span>
      </div>
      <div className="flex">
        <button
          type="button"
          className="font-medium text-indigo-600 hover:text-indigo-500"
          onClick={() => onRemove(product.SKU)}
        >
          Remove
        </button>
      </div>
    </div>
  </div>
); 