export const Newsletter = () => (
  <div className="border-t border-gray-700 pt-8 space-y-4 lg:space-y-0 lg:flex lg:items-center lg:justify-between xl:mt-0">
    <div className="space-y-2">
      <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
        Join our mailing list!
      </h4>
      <p className="text-base leading-6 text-gray-300">
        For the latest news, invites to upcoming events, new products, sales and more, sign up for our mailing list.
      </p>
    </div>
    <form className="sm:flex sm:max-w-md">
      <label htmlFor="emailAddress" className="sr-only">
        Email address
      </label>
      <input
        type="email"
        id="emailAddress"
        disabled
        required
        className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base leading-6 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs"
        placeholder="Enter your email"
      />
      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          disabled
          className="w-full bg-indigo-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base leading-6 font-medium text-white hover:bg-indigo-400 focus:outline-none focus:border-indigo-600 focus:shadow-outline-indigo active:bg-indigo-600 transition duration-150 ease-in-out"
        >
          Subscribe
        </button>
      </div>
    </form>
  </div>
); 