import { Fragment, useState } from "react";
import Link from "next/link";
import { Dialog } from "@headlessui/react";
import { MenuIcon, ShoppingBagIcon } from "@heroicons/react/outline";
import { useAuth } from "../../../lib/hooks/auth";
import { useCart } from "../../../lib/hooks/cart";
import { navigation } from "./data/navigation";
import { MobileMenu } from "./components/mobile-menu";
import { DesktopMenu } from "./components/desktop-menu";
import UserMenu from "./usermenu/usermenu";

export const Header = () => {
  const [open, setOpen] = useState(false);
  const { authObject } = useAuth();
  const { setCartOpen, cart } = useCart();

  return (
    <div className="bg-white">
      <MobileMenu 
        open={open} 
        setOpen={setOpen} 
        navigation={navigation} 
      />

      <header className="relative bg-white">
        <nav aria-label="Top" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              <button
                type="button"
                className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link href="/">
                  <div>
                    <span className="sr-only">Wave-Distro</span>
                    <img
                      className="h-10 w-auto rounded-lg"
                      src="/logo/wave-logo-icon.png"
                      alt="wave-distro logo"
                    />
                  </div>
                </Link>
              </div>

              <DesktopMenu navigation={navigation} />

              <div className="ml-auto flex items-center">
                {authObject ? (
                  <>
                    <div className="ml-4 flow-root lg:ml-6">
                      {/* CART */}
                      <div
                        onClick={() => setCartOpen(true)}
                        className="group -m-2 p-2 flex items-center hover:cursor-pointer"
                      >
                        <ShoppingBagIcon
                          className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                          {Boolean(Object.keys(cart).length)
                            ? Object.values(cart).length
                            : 0}
                        </span>
                        <span className="sr-only">items in cart, view bag</span>
                      </div>
                    </div>
                    <div className="flex lg:ml-6 z-10">
                      <UserMenu />
                    </div>
                  </>
                ) : (
                  <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                    <Link href="/signin">
                      <span className="text-sm font-medium text-gray-700 hover:text-gray-800">
                        Sign in
                      </span>
                    </Link>
                    <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                    <Link href="/signup">
                      <span className="text-sm font-medium text-gray-700 hover:text-gray-800">
                        Create account
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
