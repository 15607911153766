import Link from "next/link";
import { FooterSection } from "../types/footer.types";

interface FooterSectionProps {
  section: FooterSection;
}

export const FooterLinks = ({ section }: FooterSectionProps) => (
  <div>
    <h4 className="text-sm leading-5 font-semibold text-gray-400 tracking-wider uppercase">
      {section.title}
    </h4>
    <ul className="mt-4 space-y-4">
      {section.links.map((link) => (
        <li key={link.name}>
          <Link href={link.href}>
            <span className="text-base leading-6 text-gray-300 hover:text-white">
              {link.name}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  </div>
); 